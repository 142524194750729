import React, { useContext } from 'react';
import { Marker } from 'react-map-gl';

import { LocationContext } from '../../../contexts/location.context';
import { MarkerPopupContext } from '../../../contexts/marker_popup.context';
import { ChartContext } from '../../../contexts/chart.context';

import { ReactComponent as Pin } from './pin.svg';

import './markers.styles.scss';

export default function Markers() {
  const { selected, pastLocations, changeSelected, removeLocation } =
    useContext(LocationContext);
  const { openPopup, closePopup } = useContext(MarkerPopupContext);
  const { isOpen, toggleChart } = useContext(ChartContext);

  // Changes selected location
  const handleMarkerClick = (e, id) => {
    e.originalEvent.stopPropagation();
    changeSelected(id);
    if (!isOpen) toggleChart();
    closePopup();
  };

  // Removes locations
  const handleMarkerRightClick = (id, isSelected) => {
    if (!isSelected) {
      removeLocation(id);
      closePopup();
    }
  };

  return (
    <>
      {Object.keys(pastLocations).map((id) => {
        const loc = pastLocations[id];
        const isSelected = id === selected.id;

        const pinColor = '#3f3f3f';
        const pinProps = {
          className: 'loc-marker',
          fill: isSelected ? 'white' : pinColor,
          stroke: isSelected ? 'black' : pinColor,
          onMouseEnter: () => openPopup({ ...loc, isSelected }),
          onMouseLeave: () => closePopup(),
          onContextMenu: () => handleMarkerRightClick(id, isSelected),
        };

        return (
          <Marker
            key={id}
            longitude={loc.lng}
            latitude={loc.lat}
            onClick={(e) => handleMarkerClick(e, id)}
            style={{ zIndex: isSelected ? 2 : 1, top: -20 }}
          >
            <Pin {...pinProps} />
          </Marker>
        );
      })}
    </>
  );
}
