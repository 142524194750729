import React, { useContext } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO, subYears, addDays } from 'date-fns';

import { DataContext } from '../../../contexts/data.context';
import { OverlayContext } from '../../../contexts/overlay.context';

import Accordion from '../../accordion/accordion.component';


export default function GDDStartDateSelector({ expanded, handleChange }) {
  const { gddChartSDate, setGddChartSDate } = useContext(DataContext);
  const { today } = useContext(OverlayContext);
  if (today === null || today === undefined) return '';

  const id = 'gdd-sdate-selector';
  
  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label='Season Start Date' >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label='Only affects GDD charts'
          value={gddChartSDate}
          onChange={(newValue) => setGddChartSDate(newValue)}
          slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
          minDate={addDays(subYears(parseISO(today), 1), 1)}
          maxDate={parseISO(today)}
        />
      </LocalizationProvider>
    </Accordion>
  );
}
