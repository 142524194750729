import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

import { ReactComponent as ArrowIcon } from './arrowIcon.svg';

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderTop: `1px solid black`,
  borderBottom: `1px solid black`,
  width: '255px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowIcon fill={props.fill || 'black'} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'white',
  color: 'black',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '12px',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '24px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FinalAccordion({ expanded, handleChange, id, children, label, className='', arrowColor='black' }) {
  return (
    <StyledAccordion expanded={expanded} onChange={handleChange(id)} className={className}>
      <StyledAccordionSummary aria-controls={`${id}-content`} fill={arrowColor} >{label}</StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}