import React, { useContext } from 'react';

import { OverlayContext } from '../../../contexts/overlay.context';

import Accordion from '../../accordion/accordion.component';
import CustomRadioSelect from './custom-radio-select/custom-radio-select.component';

export default function DepartureSelector({ expanded, handleChange }) {
  const {
    comparison,
    changeComparison,
    comparisonOptions,
  } = useContext(OverlayContext);

  const id = 'departure-selector';
  
  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={`Departure From ${comparison}`} >
      <CustomRadioSelect
        title='Compare Today To'
        value={comparison}
        changeFunction={changeComparison}
        options={comparisonOptions}
      />
    </Accordion>
  );
}
