import React, { useContext } from 'react';

import { ThresholdsContext } from '../../../contexts/threshold.context';
import { DataTypeContext } from '../../../contexts/data-type.context';

import Button from '../../button/button.component';
import Accordion from '../../accordion/accordion.component';
import ThresholdItem from './threshold-selector/threshold-item/threshold-item.component';

import './threshold-selector/threshold-selector.styles.scss';

export default function HeavyRainThresholdSelector({ expanded, handleChange }) {
  const { subDataType } = useContext(DataTypeContext);
  const { heavyRainThreshold, changeHeavyRainThreshold } = useContext(ThresholdsContext);

  const origThreshold = parseFloat(subDataType.slice(1,4));
  const isOrig = origThreshold === heavyRainThreshold;
  const id = 'heavy-rain-threshold-selector';

  const handleChangeThreshold = (_, newValue) => {
    changeHeavyRainThreshold(Math.round(newValue * 10) / 10, true);
  };

  return (
    <Accordion
      expanded={expanded === id}
      handleChange={handleChange}
      id={id}
      label='Change Threshold'
    >
      <div className='threshold-selector-content-container'>
        <div className='header-container'>
          <div className='header-value'>Heavy Rain Threshold</div>
          <div className='header-controls-container'>
            <div className='header-minus'>&#8722;</div>
            <div className='header-add'>+</div>
          </div>
        </div>

        <ThresholdItem
          value={heavyRainThreshold}
          color='black'
          editThreshold={handleChangeThreshold}
          removeable={false}
          step={0.1}
          min={0}
        />

        {!isOrig && (
          <div className='add-button-container' onClick={() => changeHeavyRainThreshold(null, true)}>
            <Button buttonType='centeredSmall'>Reset Threshold</Button>
          </div>
        )}
      </div>
    </Accordion>
  );
}
