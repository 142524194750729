import React, { useContext } from 'react';

import { ThresholdsContext } from '../../../../contexts/threshold.context';
import { colors } from '../../../../contexts/app-globals';
import { ChartContext } from '../../../../contexts/chart.context';
import { DataTypeContext } from '../../../../contexts/data-type.context';

import Button from '../../../button/button.component';
import Accordion from '../../../accordion/accordion.component';
import ThresholdItem from './threshold-item/threshold-item.component';

import './threshold-selector.styles.scss';

export default function ThresholdSelector({ expanded, handleChange }) {
  const { isOpen } = useContext(ChartContext);
  const { thresholds, addThreshold, editThreshold, removeThreshold } = useContext(ThresholdsContext);
  const { isChill } = useContext(DataTypeContext);
  const thresholdCount = thresholds.length;
  const sortedThresholds = [...thresholds].sort((a, b) => b - a);

  const handleAddThreshold = () => {
    if (thresholdCount < 3) {
      addThreshold();
    }
  };

  const label = thresholdCount ? `Edit Thresholds (${thresholdCount})` : 'Set Thresholds';
  const id = 'threshold-selector';

  return (
    <Accordion
      expanded={expanded === id}
      handleChange={handleChange}
      id={id}
      label={label}
    >
      <div className='threshold-selector-content-container'>
        {!!thresholdCount && (
          <div className='header-container'>
            <div className='header-value'>{isChill ? 'Chill Unit' : 'GDD'} Threshold</div>
            <div className='header-controls-container'>
              <div className='header-minus'>&#8722;</div>
              <div className='header-remove'>Remove</div>
              <div className='header-add'>+</div>
            </div>
          </div>
        )}

        {thresholds.length ? (
          thresholds.map((threshold, i) => (
            <ThresholdItem
              key={i}
              index={i}
              value={threshold}
              color={
                isOpen ? colors[sortedThresholds.indexOf(threshold)] : 'black'
              }
              editThreshold={editThreshold}
              removeThreshold={removeThreshold}
            />
          ))
        ) : (
          <div className='no-thresholds'>No thresholds created</div>
        )}

        {thresholdCount < 3 && (
          <div className='add-button-container' onClick={handleAddThreshold}>
            <Button buttonType='centeredSmall'>Add Threshold</Button>
          </div>
        )}
      </div>
    </Accordion>
  );
}
