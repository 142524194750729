import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const BLUE_COLOR = '#6da9f7';
const RED_COLOR = '#d45959';
const GREY_COLOR = '#adadad';

function calcDailyPrecipOptions(chartData, address, threshold) {
  const timeseries = [...chartData.timeseries.current];
  while (timeseries.length && timeseries[timeseries.length - 1] === null) {
    timeseries.pop();
  }
  const dates = chartData.timeseries.dates.slice(0, timeseries.length).map(str => str.slice(5));
  const min = 0;
  const max = Math.max(...timeseries, threshold);

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    title: {
      text: 'Precipitation Throughout Season',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        groupPadding: 0,
        pointPadding: 0,
        zones: [{
          value: threshold,
          color: BLUE_COLOR
        },{
          color: RED_COLOR
        }]
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Precipitation (inches)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            value: threshold,
            label: {
              text: `${threshold} inches`,
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const pcpn = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={pcpn >= threshold ? RED_COLOR : BLUE_COLOR} />

            <div className='mc-container'>
              <div className='mc-number'>{Math.round(pcpn * 100) / 100}</div>
              <div className='mc-units'>inches</div>
            </div>
          </div>
        );
      },
    },
  };
}

function calcAnnualPrecipOptions(chartData, address) {
  const { years, toDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...fullSeason);

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: 'Annual Number of Occurences',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason.slice(0,-1),
        name: 'At End of Season',
        color: GREY_COLOR,
        opacity: 0.6,
        borderColor: GREY_COLOR,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: BLUE_COLOR,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

export { calcDailyPrecipOptions, calcAnnualPrecipOptions };