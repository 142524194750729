import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';

import { LocationContext } from './location.context';
import { LoadingContext } from './loading.context';
import { ThresholdsContext } from './threshold.context';
import { DataTypeContext } from './data-type.context';
import { OverlayContext } from './overlay.context';

import { fetchFromAcis } from '../utilities/acis-api';
import { refreshChillChartData, refreshGddChartData, refreshHeavyRainChartData, getSplines } from '../utilities/charts/chart-data-functions';

// Set up initial state of context
export const DataContext = createContext({
  chartData: {},
  gddChartSDate: '',
  setGddChartSDate: () => {},
  gddChartBase: 0,
  setGddChartBase: () => {},
  heavyRainChartSDate: '',
  setHeavyRainChartSDate: () => {}
});

// Set up context provider
export const DataProvider = ({ children }) => {
  const [gddRaw, setGddRaw] = useState({});
  const [chartData, setChartData] = useState({});
  const [splines, setSplines] = useState([]);
  const [gddChartSDate, setGddChartSDate] = useState(null);
  const [gddChartBase, setGddChartBase] = useState(50);
  const [heavyRainRaw, setHeavyRainRaw] = useState([]);
  const [heavyRainChartSDate, setHeavyRainChartSDate] = useState(null);

  // Incase we want to show anything for current season
  // eslint-disable-next-line no-unused-vars
  const [currentSeasonChillChartData, setCurrentSeasonChillChartData] = useState([]);

  const { selected } = useContext(LocationContext);
  const { addToLoading, removeFromLoading } = useContext(LoadingContext);
  const { thresholds, heavyRainThreshold } = useContext(ThresholdsContext);
  const { isChill, isGDD, isHeavyRain, subDataType, chillModel } = useContext(DataTypeContext);
  const { today } = useContext(OverlayContext);

  useEffect(() => {
    if (today) {
      setHeavyRainChartSDate(parseISO(`${today.slice(0,4)}-01-01`));
      setGddChartSDate(parseISO(`${today.slice(0,4)}-01-01`));
    }
  }, [today]);

  useEffect(() => {
    updateData(true);
  }, [selected]);

  useEffect(() => {
    updateData(false, true);
  }, [gddChartSDate, heavyRainChartSDate]);

  useEffect(() => {
    updateData();
  }, [subDataType, today, gddChartBase]);
  
  useEffect(() => {
    updateChartData();
  }, [thresholds]);

  const updateChartData = (newData=null) => {
    let newChartData = null;
    if (isChill && (newData || splines) && typeof chillModel === 'function') {
      let newCurrentSeasonChartData = null;
      ({ newChartData, newCurrentSeasonChartData } = refreshChillChartData(today, newData || splines, thresholds, chillModel));
      if (newCurrentSeasonChartData) setCurrentSeasonChillChartData(newCurrentSeasonChartData);
    } else if (isGDD && (newData || gddRaw[`Base ${gddChartBase}`])) {
      ({ newChartData } = refreshGddChartData(newData || gddRaw[`Base ${gddChartBase}`], thresholds, gddChartSDate));
    } else if (isHeavyRain && (newData || heavyRainRaw.length)) {
      ({ newChartData } = refreshHeavyRainChartData(newData || heavyRainRaw, heavyRainThreshold, heavyRainChartSDate));
    }
    setChartData(newChartData);
  }

  const updateData = async (locationChanged=false, sdateChanged=false) => {
    if (today === undefined) return;

    try {
      addToLoading(['data', 'chartData']);

      const loc = `${selected.lng},${selected.lat}`;
      
      let newData = null;
      if (isChill && (locationChanged || splines.length === 0)) {
        const startYear = parseInt(today.slice(0,4)) - 31;
        newData = await getSplines(loc, `${startYear}-07-01`, today);
        setSplines(newData);
      } else if (isGDD && gddChartSDate) {
        const sYear = gddChartSDate.getFullYear() - 31;
        const sMonth = gddChartSDate.getMonth() + 1;
        const sDay = gddChartSDate.getDate();

        const newGddRaw = (locationChanged || sdateChanged) ? {} : JSON.parse(JSON.stringify(gddRaw));
        const targetBases = [`Base ${gddChartBase}`, subDataType];
        for (let i = 0; i < targetBases.length; i++) {
          const base = targetBases[i];
          if (!Object.keys(newGddRaw).includes(base)) {
            const elems = {
              loc: loc,
              grid: 'prism',
              sdate: format(gddChartSDate, `${sYear}-MM-dd`),
              edate: today,
              elems: [{
                name: 'gdd',
                base: base.split(' ')[1],
                interval: [0,0,1],
                duration: 'std',
                season_start: [sMonth, sDay],
                reduce: 'sum'
              }],
            };
            newGddRaw[base] = await fetchFromAcis(elems);
          }
        }
        newData = newGddRaw[`Base ${gddChartBase}`];
        setGddRaw(newGddRaw);
      } else if (isHeavyRain && heavyRainChartSDate) {
        if (locationChanged || sdateChanged) {
          const sYear = heavyRainChartSDate.getFullYear() - 31;
          const elems = {
            loc: loc,
            grid: 'prism',
            sdate: format(heavyRainChartSDate, `${sYear}-MM-dd`),
            edate: today,
            elems: [{
              name: 'pcpn'
            }]
          };
          newData = await fetchFromAcis(elems);
          setHeavyRainRaw(newData);
        }
      }
      removeFromLoading('data');

      updateChartData(newData);
      removeFromLoading('chartData');
    } catch (e) {
      console.error(e);
      console.error('Data could not be fetched');
    }
  };

  const value = {
    chartData,
    gddChartSDate,
    setGddChartSDate,
    gddChartBase,
    setGddChartBase,
    heavyRainChartSDate,
    setHeavyRainChartSDate
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

DataProvider.propTypes = {
  children: PropTypes.node,
};
