import React from 'react';

import './loading.styles.scss';

export default function Loading() {
  return (
    <>
      <div className='loading-container' />
      <div className='loading-text-container'>
        <div className='loading-text'>Loading</div>
      </div>
    </>
  );
}
