import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';

import './tooltip-thresholds.styles.scss';

export default function TooltipThresholds({ thresholds, yearIdx }) {
  const tVals = [];
  const tDates = [];
  const tDIS = [];
  const tDFA = [];
  [...thresholds]
    .sort((a, b) => a.threshold - b.threshold)
    .forEach((t, i) => {
      tVals.push(
        <div key={i} className='t-number'>
          {t.threshold}
        </div>
      );

      const date = t.dateOccurred[yearIdx];
      tDates.push(
        <div key={i} className={`t-text t-section${thresholds.length === 1 ? ' t-number' : ''}`}>
          {date ? format(parseISO(date), 'MMM do, yyyy') : '-'}
        </div>
      );

      const dis = t.daysIntoSeason[yearIdx];
      tDIS.push(
        <div key={i} className='t-number t-section'>
          {dis || '-'}
        </div>
      );

      const diff = typeof dis === 'number' ? dis - t.avgDaysIntoSeason : null;
      tDFA.push(
        <div key={i} className='t-number t-section'>
          {diff > 0 && '+'}
          {diff === null ? '-' : diff}
        </div>
      );
    });

  return (
    <div
      className='tooltip-thresholds-container'
      style={{
        gridTemplateColumns:
          thresholds.length > 1
            ? `repeat(${thresholds.length}, 58px)`
            : '150px',
      }}
    >
      {tVals}
      {tDates}
      <div className='full-row'>Exceeded On</div>
      {tDIS}
      <div className='full-row'>Days Into Season</div>
      {tDFA}
      <div className='full-row'>Days From Average</div>
    </div>
  );
}

TooltipThresholds.propTypes = {
  thresholds: PropTypes.array,
  yearIdx: PropTypes.number,
};
