// Gets data from ACIS and removes days at end with missing data
export const fetchFromAcis = (elems) => {
  return fetch('https://grid2.rcc-acis.org/GridData', {
    method: 'POST',
    body: JSON.stringify(elems),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then((data) => {
      data = data.data;

      // Remove all days from end if the data is missing
      while (data[data.length - 1][1] === -999) {
        data.pop();
      }

      return data;
    });
};