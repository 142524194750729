// Name of app for use in localStorage
const NAME = 'CHILL';

// Some charting color definitions
const COLORS = ['#bae4b3', '#74c476', '#238b45'];

// Persists data item for future sessions
function storeItem(itemName, data) {
  localStorage.setItem(`${NAME}.${itemName}`, data);
}

// Gets persisted data item
function getStoredItem(itemName, parseIt=false) {
  const key = `${NAME}.${itemName}`;
  try {
    const storedValue = localStorage.getItem(key) || '';
    return (parseIt && storedValue) ? JSON.parse(storedValue) : storedValue;
  } catch {
    if (window.confirm('An error occurred trying to load you stored data. Please click "OK" to clear the data that may be causing this issue. NOTE: You may lose some of your stored selections.')) {
      localStorage.removeItem(key);
      return null;
    }
  }
}

export {
  NAME as appName,
  COLORS as colors,
  storeItem,
  getStoredItem
};