import React, { useContext, useState, useEffect } from 'react';
import { TextField } from '@mui/material';

import { DataContext } from '../../../contexts/data.context';

import Accordion from '../../accordion/accordion.component';
import Button from '../../button/button.component';


export default function GDDBaseSelector({ expanded, handleChange }) {
  const { gddChartBase, setGddChartBase } = useContext(DataContext);

  const [base, setBase] = useState(gddChartBase);

  useEffect(() => {
    setBase(gddChartBase);
  }, [gddChartBase]);

  const id = 'gdd-base-selector';
  
  const disabled = parseInt(gddChartBase) === parseInt(base);
  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={`GDD Base ${gddChartBase}`} >
      <TextField
        label='Only affects GDD Charts'
        type='number'
        value={base}
        onChange={(e) => setBase(e.target.value)}
      />
      <Button
        buttonType={disabled ? 'disabled' : 'centeredSmall'}
        onClick={() => setGddChartBase(base)}
        disabled={disabled}
        style={{
          margin: '12px auto'
        }}
      >Submit</Button>
    </Accordion>
  );
}
