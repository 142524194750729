// Inputs: t- number representing an hourly temperature observation in degrees F
// Returns the chill units for the given hourly temperature
function northCarolinaChillingUnitsModel(t) {
  let chill;
  if (t <= 34.7) {
    chill = 0;
  } else if (t <= 44.78) {
    chill = 0.5;
  } else if (t <= 55.22) {
    chill = 1;
  } else if (t <= 61.52) {
    chill = 0.5;
  } else if (t <= 66.02) {
    chill = 0;
  } else if (t <= 69.08) {
    chill = -0.5;
  } else if (t <= 71.6) {
    chill = -1;
  } else if (t <= 73.76) {
    chill = -1.5;
  } else if (73.76 < t) {
    chill = -2;
  }

  return chill;
}

function utahChillingUnitsModel(t) {
  let chill;
  if (t <= 34.52) {
    chill = 0;
  } else if (t <= 36.32) {
    chill = 0.5;
  } else if (t <= 48.38) {
    chill = 1;
  } else if (t <= 54.32) {
    chill = 0.5;
  } else if (t <= 60.62) {
    chill = 0;
  } else if (t <= 64.4) {
    chill = -0.5;
  } else if (64.4 < t) {
    chill = -1;
  }

  return chill;
}

export class DynamicChillUnitsModel {
  constructor() {
    this.e0 = 4.1535e3;
    this.e1 = 1.28888e4;
    this.a0 = 1.395e5;
    this.a1 = 2.567e18;
    this.slp = 1.6;
    this.tetmlt = 277;
    this.aa = this.a0 / this.a1;
    this.ee = this.e1 - this.e0;
    this.prevInters = 0.0730716484610116;
    this.prevIntere = 0.13272532679546323;
    this.prevXi = 0.9999968474568;
  }

  fahrToKelvin = (tempF) => {
    return (tempF - 32) * (5 / 9) + 273.15;
  };

  calcChillUnits(tempF) {
    const tempK = this.fahrToKelvin(tempF);
    const flmprt = (this.slp * this.tetmlt * (tempK - this.tetmlt)) / tempK;
    const sr = Math.exp(flmprt);
    const xi = sr / (1 + sr);
    const xs = this.aa * Math.exp(this.ee / tempK);
    const ak1 = this.a1 * Math.exp(-this.e1 / tempK);

    const inters =
      this.prevIntere < 1
        ? this.prevIntere
        : this.prevIntere - this.prevIntere * this.prevXi;
    const intere = xs - (xs - inters) * Math.exp(-ak1);
    const delt = intere < 1 ? 0 : intere * xi;

    this.prevIntere = intere;
    this.prevInters = inters;
    this.prevXi = xi;
    return delt;
  }
}

export const MODELS = [
  {
    name: 'North Carolina Model',
    model: (temp) => northCarolinaChillingUnitsModel(temp)
  },
  {
    name: 'Utah Model',
    model: (temp) => utahChillingUnitsModel(temp)
  },
  {
    name: 'Dynamic Model',
    model: (temp, modelObj) => modelObj.calcChillUnits(temp)
  }
];