import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import accessibility from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';
import coloraxis from 'highcharts/modules/coloraxis';

import Button from '../../button/button.component';

import './chart.styles.scss';

NoDataToDisplay(Highcharts);
accessibility(Highcharts);
exporting(Highcharts);
coloraxis(Highcharts);

export default function Chart({ options, width, height }) {
  const [isZoomed, setIsZoomed] = useState(false);
  const chartRef = useRef(null);

  const isSmallScreen = width < 590 - 23 || height < 225;
  const legendBreakpoint = width < 500 - 23;
  const btnsInTwoRows = width < 420 - 23;

  const handleResetZoom = () => {
    chartRef.current.chart.zoomOut();
    setIsZoomed(false);
  };

  const {
    credits,
    exporting,
    plotOptions,
    chart,
    title,
    subtitle,
    legend,
    xAxis,
    ...rest
  } = options;

  const chartOptions = {
    credits: {
      text: 'Powered by NRCC',
      href: 'http://www.nrcc.cornell.edu/',
      ...credits,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
      ...exporting,
    },
    plotOptions: {
      line: {
        marker: {
          symbol: 'circle',
          radius: 3,
        },
      },
      ...plotOptions,
    },
    chart: {
      height,
      width,
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          style: { display: 'none' },
        },
      },
      style: {
        fontFamily:
          'Raleway, Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
      },
      ...chart,
    },
    title: {
      y: btnsInTwoRows ? 20 : 10,
      style: {
        fontSize: isSmallScreen ? '12px' : '18px',
        fontWeight: isSmallScreen ? 'bold' : 'normal',
      },
      ...title,
    },
    subtitle: {
      y: btnsInTwoRows ? 34 : 30,
      ...subtitle,
    },
    legend: {
      itemStyle: {
        fontSize: isSmallScreen ? '10px' : '12px',
      },
      layout: legendBreakpoint ? 'vertical' : 'horizontal',
      ...legend,
    },
    xAxis: {
      events: {
        setExtremes: () => setIsZoomed(true),
      },
      labels: {
        style: {
          fontFamily:
            'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
        },
      },
      ...xAxis,
    },
    ...rest
  };

  return (
    <div className='chart-container'>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={chartOptions}
      />

      {isZoomed && (
        <Button onClick={handleResetZoom} buttonType='resetZoom'>
          Reset Zoom
        </Button>
      )}
    </div>
  );
}

Chart.propTypes = {
  options: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
};
