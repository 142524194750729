import React from 'react';

import './footer.styles.scss';

export default function Footer() {
  return (
    <footer>
      <div>Content coming soon...</div>
    </footer>
  );
}