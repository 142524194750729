import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';

import './custom-radio-select.styles.scss';

export default function CustomRadioSelect({
  title,
  value,
  changeFunction,
  options,
  groupedOptions
}) {
  if (options || groupedOptions) {
    return (
      <div className='radio-select-container'>
        <div className='radio-select-title'>{title}</div>
  
        <RadioGroup
          value={value}
          onChange={(e) => changeFunction(e.target.value)}
          className='radio-btns-container'
        >
          {options ? 
            options.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio className='radio-btn' />}
                label={option}
              />
            ))
            :
            Object.keys(groupedOptions).map(gName => {
              return (
                <React.Fragment key={gName}>
                  <div className='radio-select-group-title'>{title}</div>
                  {groupedOptions[gName].map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio className='radio-btn' />}
                      label={option}
                    />
                  ))}
                </React.Fragment>
              );
            })
          }
        </RadioGroup>
      </div>
    );
  }
  return <></>;
}

CustomRadioSelect.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeFunction: PropTypes.func,
  options: PropTypes.array,
  groupedOptions: PropTypes.object
};
