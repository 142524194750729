import React, { useContext } from 'react';

import { OverlayContext } from '../../contexts/overlay.context';

import './legend.styles.scss';

export default function Legend() {
  const { comparison, binColors } = useContext(OverlayContext);

  const { squares, labels } = binColors.reduce(
    (acc, color, i) => {
      acc.squares.push(
        <div
          key={i}
          className='legend-square'
          style={{ backgroundColor: color }}
        ></div>
      );
      if (i !== binColors.length - 1) {
        acc.labels.push(
          <div key={i} className='legend-label'>
            {acc.threshold}%
          </div>
        );
        acc.threshold -= 10;
      }

      return acc;
    },
    {
      squares: [],
      labels: [],
      threshold: 50,
    }
  );

  return (
    <div className='legend-container'>
      <div className='legend-title'>Departure from {comparison}</div>
      <div className='legend-labels-container'>{labels}</div>
      <div className='legend-squares-container'>{squares}</div>
    </div>
  );
}
