import { getStoredItem } from '../../contexts/app-globals';

import calcMaxValueDateOptions from './max-value-date-options';
import calcOnTodayValueAmountOptions from './onToday-value-amount-options';
import calcThresholdsOptions from './thresholds-options';
import calcTimeseriesOptions from './timeseries-options';
import { calcDailyPrecipOptions, calcAnnualPrecipOptions } from './precip-chart-options';

const chartOptionsObj = {
  gddCharts: {
    'GDD Accumulation Timeseries': calcTimeseriesOptions,
    'GDDs Achieved On This Date': calcOnTodayValueAmountOptions,
    'Date of Threshold Exceedance': calcThresholdsOptions,
  },
  chillCharts: {
    'Chill Unit Accumulation Timeseries': calcTimeseriesOptions,
    'Chill Units Achieved On This Date': calcOnTodayValueAmountOptions,
    'Date of Maximum Chill Units': calcMaxValueDateOptions,
    'Date of Threshold Exceedance': calcThresholdsOptions,
  },
  heavyRainCharts: {
    'Daily Precipitation and Threshold Exceedance': calcDailyPrecipOptions,
    'Annual Number of Occurences': calcAnnualPrecipOptions
  }
}

const allChartOptions = Object.keys(chartOptionsObj).reduce((acc, k) => {
  acc[k] = Object.keys(chartOptionsObj[k]);
  return acc;
}, {});

const loadInitialChartOptions = (lsKey) => {
  const inStorage = getStoredItem(lsKey, true);
  if (inStorage) {
    Object.keys(allChartOptions).forEach(k => {
      if (!(k in inStorage)) {
        inStorage[k] = allChartOptions[k];
      }
    });
    return inStorage;
  } else {
    return allChartOptions;
  }
}

export {
  chartOptionsObj,
  allChartOptions,
  loadInitialChartOptions
};