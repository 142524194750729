import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { LocationContext } from './location.context';

// Bounding box used to set initial view
const INIT_BOUNDS = [-126, 34, -66, 43];

// Set up initial state of context
export const MapContext = createContext({
  mapRef: null,
  viewState: {},
  handleLoad: () => null,
  handleMapClick: () => null,
  handleZoomToBbox: () => null,
  handlePanning: () => null,
  resetViewState: () => null,
  isInitView: true,
});

// Set up context provider
export const MapProvider = ({ children }) => {
  const mapRef = useRef(null);
  const [viewState, setViewState] = useState({
    bounds: INIT_BOUNDS,
  });
  const [initView, setInitView] = useState(null);
  const [isInitView, setIsInitView] = useState(true);
  const { newLocFromCoords, isOutsideLatBounds, isOutsideLngBounds } =
    useContext(LocationContext);

  useEffect(() => {
    try {
      const currCenter = mapRef.current.getCenter();
      const currZoom = mapRef.current.getZoom();

      setIsInitView(
        currCenter.lat === initView.center[1] &&
          currCenter.lng === initView.center[0] &&
          currZoom === initView.zoom
      );
    } catch {
      setIsInitView(true);
    }
  }, [viewState, initView]);

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleMapClick = async (e) => {
    const successful = await newLocFromCoords(e.lngLat);

    if (successful && mapRef.current) {
      mapRef.current.flyTo({
        center: [e.lngLat.lng, e.lngLat.lat],
        speed: 0.8,
        essential: true,
      });
    }
  };

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleZoomToBbox = async (regions, bboxes) => {
    if (mapRef.current) {
      let bbox;
      if (regions.length === 0) {
        bbox = [INIT_BOUNDS.slice(0,2),INIT_BOUNDS.slice(2)];
      } else {
        bbox = regions.reduce((acc, regName) => {
          const bbox = bboxes[regName];
          if (bbox[0][0] < acc[0][0]) acc[0][0] = bbox[0][0];
          if (bbox[0][1] < acc[0][1]) acc[0][1] = bbox[0][1];
          if (bbox[1][0] > acc[1][0]) acc[1][0] = bbox[1][0];
          if (bbox[1][1] > acc[1][1]) acc[1][1] = bbox[1][1];
          return acc;
        }, [[180,90],[-180,-90]]);
      }

      mapRef.current.fitBounds(bbox, { padding: 30 });
    }
  };

  // Limits panning to bbox coordinates
  const handlePanning = (view) => {
    if (isOutsideLatBounds(view.latitude)) {
      view.latitude = viewState.latitude;
    }

    if (isOutsideLngBounds(view.longitude)) {
      view.longitude = viewState.longitude;
    }

    setViewState((prev) => {
      return {
        ...prev,
        ...view,
      };
    });
  };

  // Increases zoom step and sets init zoom for reset button to use
  const handleLoad = (e) => {
    e.target.scrollZoom.setWheelZoomRate(1 / 100);

    const center = e.target.getCenter();
    setInitView({
      zoom: e.target.getZoom(),
      center: [center.lng, center.lat],
    });
  };

  // Moves map to initial view
  const resetViewState = () => {
    mapRef.current.flyTo({
      ...initView,
      speed: 0.8,
      essential: true,
    });
  };

  const value = {
    mapRef,
    viewState,
    handleLoad,
    handleMapClick,
    handleZoomToBbox,
    handlePanning,
    resetViewState,
    isInitView,
  };
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

MapProvider.propTypes = {
  children: PropTypes.node,
};
