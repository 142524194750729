import React, { useState } from 'react';

import {
  Box,
  Drawer,
  IconButton
} from '@mui/material';

import Accordion from '../accordion/accordion.component';
import { ReactComponent as MenuIcon } from './menu.svg';

export default function NavDrawer({ linksInfo, selectedGroup, selectedOption, handleSelect }) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(selectedGroup);

  const toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' || (event).key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  const handleExpandGroup = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelectOption = (newGroup, newOption) => {
    if (newOption !== selectedOption) {
      handleSelect(newGroup, newOption);
      toggleDrawer({ type: 'func' });
    }
  }

  const groupNames = Object.keys(linksInfo);
  return (
    <Box>
      <IconButton
        sx={{
          height: '26px',
          width: '28px',
          backgroundColor: 'black',
          borderRadius: '6px',
          margin: '3px 0 0 6px',
          padding: 0,
          '&:hover': {
            backgroundColor: 'rgb(50,50,50)',
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon fill='white' />
      </IconButton>

      <Drawer
        anchor='left'
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            backgroundColor: 'rgb(240,240,240)',
            width: 300,
            paddingTop: 20,
            paddingBottom: 20,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box role='presentation' onKeyDown={toggleDrawer}>
          {groupNames.map(groupName => 
            <Accordion key={groupName} expanded={expanded === groupName} handleChange={handleExpandGroup} id={groupName} label={groupName} className={'nav-link-group' + (selectedGroup === groupName ? ' selected-group' : '')} arrowColor={selectedGroup === groupName ? 'white' : 'black'}>
              {linksInfo[groupName].options.map(({name}) =>
                <button key={name} className={'nav-link-option' + (selectedOption === name ? ' selected-option' : '')} onClick={() => handleSelectOption(groupName, name)}>{name}</button>
              )}
            </Accordion>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}
