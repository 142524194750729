import React from 'react';

import './location-display.styles.scss';

export default function LocationDisplay({ address=null }) {
  return (
    <div className="location-display">
      {address === null ? 
        <div className="no-location-selected">
          <div className="no-location-text">No field selected</div>
        </div>
        :
        <h2 className="location-address">{address}</h2>
      }
    </div>
  );
}