import React from 'react';
import PropTypes from 'prop-types';

import './tooltip-season.styles.scss';

export default function TooltipSeason({ year, color }) {
  return (
    <div className='tooltip-season-line'>
      <div className='tooltip-full-line' style={{ backgroundColor: color }} />
      <div className='tooltip-season-container'>
        <div className='tooltip-flex-line' style={{ backgroundColor: color }} />
        <div className='tooltip-season-number'>{year}</div>
        <div className='tooltip-flex-line' style={{ backgroundColor: color }} />
      </div>
      <div className='tooltip-full-line' style={{ backgroundColor: color }} />
    </div>
  );
}

TooltipSeason.propTypes = {
  color: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
