import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const TEXT_TABS = ['How To Use Tool', 'FAQ', 'About The Data'];

// Set up initial state of context
export const TextPanelContext = createContext({
  tabNames: [],
  tabSelected: 0,
  setTabIdx: () => null
});

// Set up context provider
export const TextPanelProvider = ({ children }) => {
  const [tabIdx, setTabIdx] = useState(0);
  
  const value = {
    tabNames: TEXT_TABS,
    tabSelected: tabIdx,
    setTabIdx
  };
  return (
    <TextPanelContext.Provider value={value}>{children}</TextPanelContext.Provider>
  );
};

TextPanelProvider.propTypes = {
  children: PropTypes.node,
};
