import React, { useContext } from 'react';

import { ChartContext } from './contexts/chart.context';
import { LoadingContext } from './contexts/loading.context';
import { OverlayContext } from './contexts/overlay.context';

import Map from './components/map/map.component';
import Charts from './components/charts/charts.component';
import OptionsPanel from './components/options-panel/options-panel.component';
import Loading from './components/loading/loading.component';
import Legend from './components/legend/legend.component';
import DataDateTypeTitle from './components/data-date-type-title/data-date-type-title.component';
import Header from './components/header/header.component';
import DataTypeNav from './components/data-type-nav/data-type-nav.component';
import Footer from './components/footer/footer.component';
import TextPanel from './components/text-panel/text-panel.component';

import './App.scss';

function App() {
  const { isOpen } = useContext(ChartContext);
  const { isLoading } = useContext(LoadingContext);
  const { isRegionSelected } = useContext(OverlayContext);

  return (
    <div className='App'>
      <div>
        <Header />
        <DataTypeNav />
      </div>

      <main>
        <DataDateTypeTitle />
        
        <div className='map-content-container'>
          <OptionsPanel />
          <div className='main-panel'>
            <Map />
            {!isOpen && isRegionSelected && <Legend />}
            {isOpen && <Charts />}
          </div>
        </div>

        <TextPanel />

        {isLoading && <Loading />}
      </main>

      <Footer />
    </div>
  );
}

export default App;
