import React, { useContext } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { OverlayContext } from '../../../contexts/overlay.context';

import Accordion from '../../accordion/accordion.component';

export default function RegionSelector({ expanded, handleChange}) {
  const {
    regions,
    updateRegions,
    regionOptions
  } = useContext(OverlayContext);

  const label = regions.length === 0 ? 'Select Regions' : `Showing ${regions.length} Regions`;
  const id = 'region-selector';

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <div className='region-selection-container'>
        <div className='regions-title'>Select Region</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={regions.length === regionOptions.length}
                onChange={() => updateRegions('all')}
              />
            }
            label='All'
          />
          {regionOptions.map((regionId) => (
            <FormControlLabel
              key={regionId}
              control={
                <Checkbox
                  checked={regions.includes(regionId)}
                  onChange={() => updateRegions(regionId)}
                />
              }
              label={regionId}
            />
          ))}
          <FormControlLabel
            control={
              <Checkbox
                checked={regions.length === 0}
                onChange={() => updateRegions('none')}
              />
            }
            label='None'
          />
        </FormGroup>
      </div>
    </Accordion>
  );
}
