import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import './markdown-to-jsx.styles.scss';


export default function MarkdownToJsx({ path, fallback, onInternalLinkClick=()=>null }) {
  const [mdText, setMdText] = useState('');

  useEffect(() => {
    (async () => {
      let md = '';
      let response = await fetch(path, { headers: { 'Accept': 'text/markdown' } });
      if (!response.ok && fallback) {
        response = await fetch(fallback, { headers: { 'Accept': 'text/markdown' } });
      }
      if (response.ok) {
        md = await response.text();
      }
      setMdText(md);
    })();
  }, [path, fallback]);

  const handleClickInternalLink = (e, choice) => {
    e.preventDefault();
    onInternalLinkClick(choice);
  };

  return <div className='markdown-content'>
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        a: props => {
          if (props.href.startsWith('#%5E#')) {
            return <a {...props} href={props.href.slice(5)} className={`superscript-anchor ${props.className || ''}`} target='_blank' rel='noreferrer'>{props.children}</a>;
          } else if (props.href.startsWith('#internal#')) {
            const target = props.href.slice(10);
            return <button {...props} className={`btn-as-anchor ${props.className || ''}`} onClick={(e) => handleClickInternalLink(e, target)}>{props.children}</button>;
          } else {
            return <a {...props} target='_blank' rel='noreferrer'>{props.children}</a>;
          }
        }
    }}
    >{mdText}</Markdown>
  </div>;
}