import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import { ReactComponent as XIcon } from './closeIcon.svg';

import './threshold-item.styles.scss';

export default function ThresholdItem({ value, index=null, color, editThreshold, removeThreshold=()=>{}, removeable=true, step=100, min=0, max=Infinity }) {
  const handleIncrementThreshold = () => {
    const newValue = value + step;
    if (newValue <= max) {
      editThreshold(index, newValue);
    }
  };

  const handleDecrementThreshold = () => {
    const newValue = value - step;
    if (newValue >= min) {
      editThreshold(index, newValue);
    }
  };

  const handleRemoveThreshold = () => {
    removeThreshold(index);
  };

  const handleTyping = (e) => {
    if (!Number.isNaN(e.target.value)) {
      editThreshold(index, parseInt(e.target.value));
    }
  };

  return (
    <div className='threshold-item-container'>
      <TextField
        variant='standard'
        value={value}
        onChange={handleTyping}
        className='threshold-item-value'
        inputProps={{ style: { textAlign: 'center' } }}
      />

      <div className='threshold-item-controls' style={{ color, userSelect: 'none' }}>
        <div className='arrow-container' onClick={handleDecrementThreshold}>
          <span className='arrow'>&#10094;</span>
        </div>
        {removeable &&
          <div className='remove-container' onClick={handleRemoveThreshold}>
            <XIcon className='remove' fill={color} />
          </div>
        }
        <div className='arrow-container' onClick={handleIncrementThreshold}>
          <span className='arrow'>&#10095;</span>
        </div>
      </div>
    </div>
  );
}

ThresholdItem.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  color: PropTypes.string,
};
