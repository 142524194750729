import React, { useContext } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { ChartContext } from '../../../contexts/chart.context';

import Accordion from '../../accordion/accordion.component';

export default function ChartSelector({ expanded, handleChange }) {
  const { selectedCharts, updateSelectedCharts, chartOptions } = useContext(ChartContext);

  const id = 'chart-selector';
  const label = selectedCharts.length === 0 ? 'Select Charts' : `Showing ${selectedCharts.length} Charts`;

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label}>
      <div className='chart-checkboxes-container'>
        <FormGroup>
          {chartOptions.map(chartName => (
            <FormControlLabel
              key={chartName}
              control={
                <Checkbox
                  checked={selectedCharts.includes(chartName)}
                  onChange={() => updateSelectedCharts(chartName)}
                />
              }
              label={chartName}
            />
          ))}
        </FormGroup>
      </div>
    </Accordion>
  );
}
