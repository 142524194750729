import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// Set up initial state of context
export const MarkerPopupContext = createContext({
  popupLocation: false,
  openPopup: () => null,
  closePopup: () => null,
});

// Set up context provider
export const MarkerPopupProvider = ({ children }) => {
  const [popupLocation, setPopupLocation] = useState(false);

  const openPopup = (newContent) => {
    setPopupLocation(newContent);
  };

  const closePopup = () => {
    setPopupLocation(false);
  };

  const value = { popupLocation, openPopup, closePopup };
  return (
    <MarkerPopupContext.Provider value={value}>
      {children}
    </MarkerPopupContext.Provider>
  );
};

MarkerPopupProvider.propTypes = {
  children: PropTypes.node,
};
