import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { storeItem, getStoredItem } from './app-globals';
import { LoadingContext } from './loading.context';
import { DataTypeContext } from './data-type.context';
import { MapContext } from './map.context';

const REGIONS_LS_KEY = 'regions';

const binColors = [
  'rgb(0.0,0.0,127.5)',
  'rgb(0.0,0.0,232.0)',
  'rgb(0.0,56.5,255.0)',
  'rgb(0.0,148.5,255.0)',
  'rgb(12.1,244.5,234.7)',
  'rgb(86.3,255.0,160.5)',
  'rgb(160.5,255.0,86.3)',
  'rgb(234.7,255.0,12.1)',
  'rgb(255.0,170.6,0.0)',
  'rgb(255.0,85.4,0.0)',
  'rgb(232.0,0.2,0.0)',
  'rgb(127.5,0.0,0.0)',
];

const COMPARISON_OPTIONS = [ 'Last Year', 'Normal' ];

const REGIONAL_INFO = [{
  name: 'Midwest',
  stateFips: ['27', '19', '29', '17', '55', '26', '18', '39'],
  statePostalCodes: ['MN','WI','MI','IA','IL','IN','OH','MO'],
  bbox: [[-97.382813,35.871247],[-80.354004,49.496675]]
},{
  name: 'Northeast',
  stateFips: ['23', '33', '50', '25', '09', '44', '36', '42', '34', '10', '24', '54', '11'],
  statePostalCodes: ['ME','VT','NH','MA','RI','CT','NY','PA','MD','DE','WV','NJ','DC'],
  bbox: [[-82.749023,37.230328],[-66.752930,47.502359]]
},{
  name: 'Northern Plains',
  stateFips: ['30', '38', '46', '56', '31', '08'],
  statePostalCodes: ['CO','WY','MT','ND','SD','NE'],
  bbox: [[-116.191406,36.809285],[-95.031738,49.095452]]
},{
  name: 'Pacific Northwest',
  stateFips: ['53', '41', '16'],
  statePostalCodes: ['WA','OR','ID'],
  bbox: [[-125.046387,41.902277],[-110.852051,49.181703]]
},{
  name: 'Southeast',
  stateFips: ['51', '21', '47', '37', '45', '13', '12', '01', '28', '05', '22'],
  statePostalCodes: ['VA','NC','SC','GA','FL','KY','TN','AR','LA','MS','AL'],
  bbox: [[-94.812012,24.966140],[-75.607910,39.520992]]
},{
  name: 'Southern Plains',
  stateFips: ['20', '40', '48'],
  statePostalCodes: ['TX','OK','KS'],
  bbox: [[-107.028809,25.700938],[-93.317871,40.229218]]
},{
  name: 'Southwest',
  stateFips: ['06', '32', '49', '04', '35'],
  statePostalCodes: ['CA','NV','UT','AZ','NM'],
  bbox: [[-124.716797,31.240985],[-102.832031,42.081917]]
}];
const regionOptions = REGIONAL_INFO.map((regObj) => regObj.name);
const stateFips = REGIONAL_INFO.map((regObj) => regObj.stateFips).flat();
const regionBBoxes = REGIONAL_INFO.reduce((acc,regObj) => {
  acc[regObj.name] = regObj.bbox;
  return acc;
}, {});

const convertToColors = (dataObj, whichUnits) => {
  const colors = {};
  const usedGeoids = [];

  let bcs = [...binColors];
  if (whichUnits === 'gdds') {
    bcs.reverse();
  }

  for (let region of regionOptions) {
    colors[region] = [];
    for (const [geoid, percentage] of Object.entries(dataObj[region])) {
      if (usedGeoids.includes(geoid)) continue;
      for (let i = 0; i < 12; i++) {
        const threshold = (i - 5) * 10;
        if (percentage <= threshold || i === 11) {
          colors[region].push(geoid, bcs[11 - i]);
          usedGeoids.push(geoid);
          break;
        }
      }
    }
  }

  return colors;
};

// Set up initial state of context
export const OverlayContext = createContext({
  comparison: '',
  changeComparison: () => null,
  regions: [],
  updateRegions: () => null,
  comparisonOptions: [],
  isRegionSelected: false,
  regionOptions: [],
  stateFips: [],
  countyColors: [],
  binColors: [],
  today: null
});

// Set up context provider
export const OverlayProvider = ({ children }) => {
  const [chillDepartureData, setChillDepartureData] = useState({});
  const [gddDepartureData, setGddDepartureData] = useState({});
  const [heavyRainDepartureData, setHeavyRainDepartureData] = useState({});
  const [comparison, setComparison] = useState('Last Year');
  const [regions, setRegions] = useState(getStoredItem(REGIONS_LS_KEY, true) || regionOptions);
  const [countyChillColors, setCountyChillColors] = useState({});
  const [countyGddColors, setCountyGddColors] = useState({});
  const [countyHeavyRainColors, setCountyHeavyRainColors] = useState({});
  
  const { isChill, isGDD, isHeavyRain, subDataType } = useContext(DataTypeContext);
  const { addToLoading, removeFromLoading } = useContext(LoadingContext);
  const { handleZoomToBbox, resetViewState } = useContext(MapContext);

  useEffect(() => {
    if (Object.keys(chillDepartureData).length) {
      setCountyChillColors(convertToColors(chillDepartureData['data'][comparison], 'chillUnits'))
    }

    if (Object.keys(gddDepartureData).length) {
      setCountyGddColors(convertToColors(gddDepartureData['gdd_departures'][comparison], 'gdds'))
    }

    if (Object.keys(heavyRainDepartureData).length) {
      setCountyHeavyRainColors(convertToColors(heavyRainDepartureData['pcpn_departures'][comparison], 'heavyRain'))
    }
  }, [comparison]);

  useEffect(() => {
    (async () => {
      addToLoading(['overlayData']);
      
      const response = await fetch(
        // `/data/${subDataType.replace('>', '').split(' ').join('_').toLowerCase()}.json`,
        `/overlays/${subDataType.replace('>', '').split(' ').join('_').toLowerCase()}.json`,
        { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_BUCKET_AUTH}` } }
      );
      const data = await response.json();
      if (data && Object.keys(data).length) {
        if (isChill) {
          setChillDepartureData(data);
          setCountyChillColors(convertToColors(data['data'][comparison], 'chillUnits'))
        } else if (isGDD) {
          setGddDepartureData(data);
          setCountyGddColors(convertToColors(data['gdd_departures'][comparison], 'gdds'))
        } else if (isHeavyRain) {
          setHeavyRainDepartureData(data);
          setCountyHeavyRainColors(convertToColors(data['pcpn_departures'][comparison], 'heavyRain'))
        }
      }
      removeFromLoading('overlayData');
    })();
  }, [subDataType]);

  const changeComparison = (newComparison) => {
    setComparison(newComparison);
  };

  const updateRegions = (regionOfInterest) => {
    let newRegions = [];
    if (regionOptions.includes(regionOfInterest)) {
      if (regions.includes(regionOfInterest)) {
        newRegions = regions.filter((r) => r !== regionOfInterest);
      } else {
        newRegions = [...regions, regionOfInterest];
      }
    } else if (regionOfInterest === 'all') {
      newRegions = regionOptions;
    }
    storeItem(REGIONS_LS_KEY, JSON.stringify(newRegions));
    setRegions(newRegions);

    if (regionOfInterest === 'all' || regionOfInterest === 'none') {
      resetViewState();
    } else {
      handleZoomToBbox(newRegions, regionBBoxes);
    }
  };

  const consolidateColorDefs = (colorData, regions) => {
    const defaultColor = 'rgba(0,0,0,0)';
    let colors = [
      'match',
      ['get', 'GEOID']
    ];
    
    if (Object.keys(colorData).length === 0 || regions.length === 0) {
      return defaultColor;
    }
    regions.forEach(regName => {
      colors = colors.concat(colorData[regName]);
    });
    colors.push(defaultColor);
    return colors;
  };

  const value = {
    comparison,
    changeComparison,
    comparisonOptions: COMPARISON_OPTIONS,
    regions,
    updateRegions,
    isRegionSelected: regions.length > 0,
    regionOptions,
    countyColors: consolidateColorDefs(isChill ? countyChillColors : (isGDD ? countyGddColors : countyHeavyRainColors), regions),
    binColors: isGDD ? binColors.toReversed() : binColors,
    stateFips,
    today: isChill ? chillDepartureData['data_date'] : (isGDD ? gddDepartureData['data_date'] : heavyRainDepartureData['data_date'])
  };
  return (
    <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>
  );
};

OverlayProvider.propTypes = {
  children: PropTypes.node,
};
