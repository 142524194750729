import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// Set up initial state of context
export const LoadingContext = createContext({
  isLoading: false,
  addToLoading: () => null,
  removeFromLoading: () => null,
});

// Set up context provider
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState([]);

  const addToLoading = (keys) => {
    setLoading((prev) => [...prev, ...keys]);
  };

  const removeFromLoading = (key) => {
    setLoading((currLoading) => {
      const newLoading = [...currLoading];
      const idx = newLoading.indexOf(key);
  
      if (idx > -1) {
        newLoading.splice(idx, 1);
      }  
      return newLoading;
    });
  };

  const value = {
    isLoading: loading.length > 0,
    addToLoading,
    removeFromLoading
  };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node,
};
