import React, { useContext } from 'react';
import { parseISO, format } from 'date-fns'

import { OverlayContext } from '../../contexts/overlay.context';
import { DataTypeContext } from '../../contexts/data-type.context';
import { ChartContext } from '../../contexts/chart.context';
import { DataContext } from '../../contexts/data.context';

import './data-date-type-title.styles.scss';

export default function DataDateTypeTitle() {
  const { dataType, subDataType, isGDD, isChill, isHeavyRain } = useContext(DataTypeContext);
  const { gddChartSDate, gddChartBase, heavyRainChartSDate } = useContext(DataContext);
  const { isOpen } = useContext(ChartContext);
  const { today } = useContext(OverlayContext);

  let sdate = '';
  let edate = '';
  if (today) {
    edate = today === undefined ? '' : format(parseISO(today), 'LLLL do, yyyy');
    if (isGDD || isHeavyRain) {
      if (isOpen) {
        sdate = format(isGDD ? gddChartSDate : heavyRainChartSDate, 'LLLL do, yyyy')
      } else {
        sdate = format(parseISO(`${today.slice(0,4)}-01-01`), 'LLLL do, yyyy')
      }
    } else if (isChill) {
      sdate = format(parseISO(`${today.slice(0,4)}-07-01`), 'LLLL do, yyyy')
    }
  }

  let dataString = `${subDataType} ${dataType}`;
  if (isGDD && isOpen) {
    const parts = dataString.split(' ');
    parts[1] = gddChartBase;
    dataString = parts.join(' ');
  } else if (isHeavyRain) {
    dataString = dataString.replace('Heavy Rain', 'Precipitation');
  }
  
  return <div className='data-date-type-container'>
    <h2 className='data-date-type-text'>{dataString}: {sdate} - {edate}</h2>
  </div>;
}