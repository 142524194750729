import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { storeItem, getStoredItem } from '../contexts/app-globals';
import { DataTypeContext } from './data-type.context';

// Set up initial state of context
export const ThresholdsContext = createContext({
  allThresholds: {},
  thresholds: [],
  addThreshold: () => null,
  editThreshold: () => null,
  removeThreshold: () => null,
  heavyRainThreshold: 0,
  changeHeavyRainThreshold: () => null
});

// Set up context provider
export const ThresholdsProvider = ({ children }) => {
  const { subDataType } = useContext(DataTypeContext);

  const [allThresholds, setAllThresholds] = useState(getStoredItem('thresholds', true) || {});
  const [heavyRainPage, setHeavyRainPage] = useState(getStoredItem('heavy-rain-page', true) || 0.5);
  const [customHeavyRainThreshold, setCustomHeavyRainThreshold] = useState(getStoredItem('heavy-rain-custom', true) || null);

  useEffect(() => {
    storeItem('thresholds', JSON.stringify(allThresholds));
  }, [allThresholds]);

  const addThreshold = () => {
    setAllThresholds((prev) => ({ ...prev, [subDataType]: [ ...(prev[subDataType] || []), 1000] }));
  };

  const editThreshold = (indexToEdit, newValue) => {
    setAllThresholds(prev => ({
      ...prev,
      [subDataType]: prev[subDataType].map((value, i) => (i === indexToEdit ? newValue : value))
    }));
  };

  const removeThreshold = (indexToRemove) => {
    setAllThresholds(prev => ({
      ...prev,
      [subDataType]: [ ...prev[subDataType].slice(0, indexToRemove), ...prev[subDataType].slice(indexToRemove + 1) ]
    }));
  };

  const changeHeavyRainThreshold = (newThreshold, custom=false) => {
    if (custom) {
      storeItem('heavy-rain-custom', JSON.stringify(newThreshold));
      setCustomHeavyRainThreshold(newThreshold);
    } else {
      storeItem('heavy-rain-page', JSON.stringify(newThreshold));
      setHeavyRainPage(newThreshold);
    }
  };

  const value = {
    thresholds: allThresholds[subDataType] || [],
    addThreshold,
    editThreshold,
    removeThreshold,
    heavyRainThreshold: customHeavyRainThreshold === null ? heavyRainPage : customHeavyRainThreshold,
    changeHeavyRainThreshold
  };
  return (
    <ThresholdsContext.Provider value={value}>
      {children}
    </ThresholdsContext.Provider>
  );
};

ThresholdsProvider.propTypes = {
  children: PropTypes.node,
};
