import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  MenuItem,
  MenuList,
  Typography,
  Popper,
  Grow,
  Paper,
  ListItemText,
} from '@mui/material';

function NavLinkGroup({ groupName, selectedGroup, selectedOption, options, handleSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isActiveGroup = groupName === selectedGroup;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Popper
        id={`${groupName}-menu`}
        open={open}
        anchorEl={anchorEl}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={handleClose}
        placement='bottom'
        transition
        sx={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
            <Paper
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                border: '1px solid rgb(242,242,242)',
                borderTop: '2px solid rgb(189,187,187)',
              }}
            >
              <MenuList>
                {options.map(({name}) => {
                  const isActiveLink = selectedOption === name;

                  return (
                    <MenuItem
                      key={name}
                      onClick={() => handleSelect(groupName, name)}
                      sx={{
                        backgroundColor: isActiveLink
                          ? '#576CB9'
                          : 'white',
                        color: isActiveLink
                        ? 'white'
                        : 'black',
                        '&:hover': {
                          backgroundColor: isActiveLink
                          ? '#576CB9'
                          : 'rgba(0,0,0,0.08)'
                        }
                      }}
                    >
                      <ListItemText>{name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Button
        disableRipple
        id={`${groupName}-button`}
        aria-controls={anchorEl ? `${groupName}-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        sx={{
          borderRadius: 0,
          height: '100%',
          position: 'relative',
          boxSizing: 'border-box',
          backgroundColor: isActiveGroup
            ? 'darkblue'
            : 'rgba(0,0,0,0)',
          color: isActiveGroup
            ? 'white'
            : 'black',
          fontWeight: isActiveGroup
            ? 'bold'
            : 'normal',
          '&:hover': {
            backgroundColor: isActiveGroup
            ? 'darkblue'
            : 'rgba(0,0,0,0.08)'
          }
        }}
      >
        <Typography variant='links'>{groupName}</Typography>
      </Button>
    </Box>
  );
}

NavLinkGroup.propTypes = {
  group: PropTypes.object,
};


export default function NavBar({ linksInfo, selectedGroup, selectedOption, handleSelect }) {
  const groupNames = Object.keys(linksInfo);
  return groupNames.map((name, i) => {
      return (
        <Fragment key={name}>
          <NavLinkGroup
            selectedGroup={selectedGroup}
            selectedOption={selectedOption}
            groupName={name}
            options={linksInfo[name].options}
            handleSelect={handleSelect}
          />

          {i !== groupNames.length - 1 && <div className='nav-group-divider' />}
        </Fragment>
      );
    }
  );
}

NavBar.propTypes = {
  linksInfo: PropTypes.object,
  selectedGroup: PropTypes.string,
  selectedOption: PropTypes.string,
  handleSelect: PropTypes.func
};