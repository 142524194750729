import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { storeItem, getStoredItem } from './app-globals';
import { MODELS } from '../utilities/chill-models';

const DATA_TYPE_LS_KEY = 'dataType';
const DATA_TYPES = {
  'Chill Units': {
    options: MODELS
  },
  'GDDs': {
    options: [{
      name: 'Base 50'
    },{
      name: 'Base 32'
    }]
  },
  'Heavy Rain': {
    options: [{
      name: '>0.5 inches'
    },{
      name: '>1.0 inches'
    }]
  }
};

const DEFAULT_DATA_TYPE = Object.keys(DATA_TYPES)[0];
const DEFAULT_SUB_DATA_TYPE = DATA_TYPES[DEFAULT_DATA_TYPE].options[0].name;

// Set up initial state of context
export const DataTypeContext = createContext({
  dataType: '',
  subDataType: '',
  availableDataTypes: [],
  availableSubDataTypes: [],
  changeDataType: () => null,
  isGDD: false,
  isChill: false,
  chillModel: () => null
});

// Set up context provider
export const DataTypeProvider = ({ children }) => {
  const [dataType, setDataType] = useState(getStoredItem(DATA_TYPE_LS_KEY + '-type') || DEFAULT_DATA_TYPE);
  const [subDataType, setSubDataType] = useState(getStoredItem(DATA_TYPE_LS_KEY + '-subtype') || DEFAULT_SUB_DATA_TYPE);

  const changeDataType = (newDataType, newSubDataType) => {
    storeItem(DATA_TYPE_LS_KEY+'-type', newDataType);
    storeItem(DATA_TYPE_LS_KEY+'-subtype', newSubDataType);
    setDataType(newDataType);
    setSubDataType(newSubDataType);
  };

  const isChill = dataType === 'Chill Units';
  const value = {
    dataType,
    subDataType,
    allDataTypes: DATA_TYPES,
    changeDataType,
    isGDD: dataType === 'GDDs',
    isHeavyRain: dataType === 'Heavy Rain',
    isChill,
    chillModel: isChill ? MODELS.find(m => m.name === subDataType).model : () => null
  };
  return (
    <DataTypeContext.Provider value={value}>
      {children}
    </DataTypeContext.Provider>
  );
};

DataTypeProvider.propTypes = {
  children: PropTypes.node,
};