import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { storeItem, getStoredItem } from './app-globals';

// Bounding box used to limit panning
const BBOX = {
  north: 53.3,
  south: 19.8,
  east: -54.0,
  west: -137.8,
};

// If first visit or no locations have been selected, use this address
const DEFAULT_LOCATION = {
  address: '213 Warren Road, Ithaca, New York',
  id: 'default',
  lat: 42.457975,
  lng: -76.46754,
};

// Clamps latitude
function isOutsideLatBounds(lat) {
  return lat > BBOX.north || lat < BBOX.south;
}

// Clamps longitude
function isOutsideLngBounds(lng) {
  return lng > BBOX.east || lng < BBOX.west;
}

// Gets the address name for a set of coordinates if it is within the list of allowed states
function getLocation(lat, lng, token) {
  if (isOutsideLatBounds(lat) || isOutsideLngBounds(lng)) {
    return false;
  }

  return fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&access_token=${token}`,
    { method: 'GET' }
  )
    .then((response) => response.json())
    .then((res) => {
      const feature = res.features[0];
      const countryCode =
        feature.context[feature.context.length - 1].short_code;
      if (countryCode !== 'us') {
        throw new Error('Out of bounds');
      }

      // Construct user friendly address
      const address = feature.place_name
        .replace(', United States', '')
        .replace(/\s\d{5}/g, '');

      return {
        id: String(Date.now()),
        address,
        lat,
        lng,
      };
    })
    .catch(() => false);
}

// Set up initial state of context
export const LocationContext = createContext({
  selected: null,
  pastLocations: {},
  newLocFromCoords: () => null,
  isOutsideLatBounds: () => null,
  isOutsideLngBounds: () => null,
  changeSelected: () => null,
  removeLocation: () => null,
});

// Set up context provider
export const LocationProvider = ({ children }) => {
  const [selected, setSelected] = useState(getStoredItem('selected', true) || DEFAULT_LOCATION.id);
  const [pastLocations, setPastLocations] = useState(getStoredItem('locations', true) || { [DEFAULT_LOCATION.id]: DEFAULT_LOCATION });

  // Persists location settings for future sessions
  function storeLocations(selected, locations) {
    storeItem('selected', JSON.stringify(selected));
    storeItem('locations', JSON.stringify(locations));
  }

  // Tries to get a new location from the passed coordinates, returns true for successful or false for failed
  const newLocFromCoords = async (coords) => {
    const results = await getLocation(coords.lat, coords.lng, process.env.REACT_APP_MAPBOX_TOKEN);
    if (results) {
      const newPastLocs = {
        ...pastLocations,
        [results.id]: results,
      };

      storeLocations(results.id, newPastLocs);
      setPastLocations(newPastLocs);
      setSelected(results.id);
    }
    return !!results;
  };

  // Handles changing selected to a past location
  const changeSelected = (id) => {
    storeLocations(id, pastLocations);
    setSelected(id);
  };

  // Handles removing a location from past locations
  const removeLocation = (id) => {
    if (id !== selected) {
      const newPastLocs = { ...pastLocations };
      delete newPastLocs[id];
      storeLocations(selected, newPastLocs);
      setPastLocations(newPastLocs);
    }
  };

  const value = {
    selected: pastLocations[selected],
    pastLocations,
    newLocFromCoords,
    isOutsideLatBounds,
    isOutsideLngBounds,
    changeSelected,
    removeLocation,
  };
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.node,
};
