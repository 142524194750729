import React, { useContext } from 'react';
import { Popup } from 'react-map-gl';

import { MarkerPopupContext } from '../../../contexts/marker_popup.context';
import roundXDigits from '../../../utilities/round';

import './popup.styles.scss';

// Determines if user device is a touchscreen device, returns boolean
function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export default function PopupComponent() {
  const { popupLocation, closePopup } = useContext(MarkerPopupContext);

  return (
    <>
      {popupLocation && (
        <Popup
          longitude={popupLocation.lng}
          latitude={popupLocation.lat}
          closeOnClick={false}
          closeButton={false}
          onClose={closePopup}
          offset={{
            center: [0, 0],
            left: [12, 0],
            right: [-12, 0],
            top: [0, -6],
            'top-left': [10, 3],
            'top-right': [-20, 3],
            bottom: [0, -16],
            'bottom-left': [10, -12],
            'bottom-right': [-20, -12],
          }}
          style={{ zIndex: 4 }}
        >
          <h3>{popupLocation.address}</h3>
          <h4>
            Coordinates: {roundXDigits(popupLocation.lng, 5)},{' '}
            {roundXDigits(popupLocation.lat, 5)}
          </h4>
          {popupLocation.isSelected ? (
            ''
          ) : (
            <>
              <h5>Click to Use</h5>
              {isTouchDevice() ? (
                <h5>Click and Hold to Remove</h5>
              ) : (
                <h5>Right Click to Remove</h5>
              )}
            </>
          )}
        </Popup>
      )}
    </>
  );
}
