/* eslint-disable import/no-webpack-loader-syntax */
import React, { useContext } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import Map, { Source, Layer } from 'react-map-gl';


import { MapContext } from '../../contexts/map.context';
import { OverlayContext } from '../../contexts/overlay.context';
import { ChartContext } from '../../contexts/chart.context';

import Markers from './markers/markers.component';
import Popup from './popup/popup.component';
import Button from '../button/button.component';

import './map.styles.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// Set token for mapbox API
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function MapComponent() {
  const { mapRef, viewState, handleLoad, handleMapClick, handlePanning, resetViewState, isInitView } =
    useContext(MapContext);
  const { countyColors, stateFips } = useContext(OverlayContext);
  const { isOpen, toggleChart } = useContext(ChartContext);
  const showResetZoomBtn = !isOpen;

  return (
    <div className='map-container'>
      {showResetZoomBtn && !isInitView &&
        <Button buttonType='resetZoom' onClick={resetViewState}>
          Reset Zoom
        </Button>
      }
      
      <Map
        {...viewState}
        ref={mapRef}
        mapStyle='mapbox://styles/precipadmin/cl9isweo8005w14qs6gsnguyy'
        boxZoom={false}
        dragRotate={false}
        touchPitch={false}
        doubleClickZoom={false}
        attributionControl={false}
        onMove={(evt) => handlePanning(evt.viewState)}
        onClick={(e) => {
          handleMapClick(e);
          if (!isOpen) toggleChart();
        }}
        onLoad={handleLoad}
        minZoom={3.1}
      >
        <Markers />
        <Popup />

        <Source
          type='vector'
          url='mapbox://precipadmin.b2ztd99d'
          id='county-colors'
        >
          <Layer
            id='county-fill'
            type='fill'
            source-layer='cb_2019_us_county_500k-9krtz6'
            paint={{
              'fill-color': countyColors,
              'fill-opacity': 0.5,
            }}
            filter={['in', ['get', 'STATEFP'], ['literal', stateFips]]}
          />
        </Source>
      </Map>
    </div>
  );
}
