// Takes a number or string to round and a number for digits to round to, returns a rounded number
export default function roundXDigits(number, digits) {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  const res = digits === 0 ? Math.round(number).toFixed(digits) : (
    Math.round(Math.round(number * Math.pow(10, digits + 1)) / 10) /
    Math.pow(10, digits)
  ).toFixed(digits);

  return parseFloat(res);
}
