import React, { useContext } from 'react';

import { DataTypeContext } from '../../contexts/data-type.context';
import { DataContext } from '../../contexts/data.context';
import { ThresholdsContext } from '../../contexts/threshold.context';

import NavDrawer from './nav-drawer.component';
import NavBar from './nav-bar.component';

import './data-type-nav.styles.scss';

export default function DataTypeNav() {
  const { dataType, subDataType, allDataTypes, changeDataType } = useContext(DataTypeContext);
  const { setGddChartBase } = useContext(DataContext);
  const { changeHeavyRainThreshold } = useContext(ThresholdsContext);

  const handleDataTypeChange = (newDataType, newSubDataType) => {
    if (newDataType === 'GDDs') {
      setGddChartBase(parseInt(newSubDataType.split(' ')[1]));
    } else if (newDataType === 'Heavy Rain') {
      changeHeavyRainThreshold(parseFloat(newSubDataType.slice(1,4)));
    }
    changeDataType(newDataType, newSubDataType);
  };

  return (
    <nav>
      <div id='nav-drawer'>
        <NavDrawer
          linksInfo={allDataTypes}
          selectedGroup={dataType}
          selectedOption={subDataType}
          handleSelect={handleDataTypeChange}
        />
      </div>

      <div id='nav-bar'>
        <NavBar
          linksInfo={allDataTypes}
          selectedGroup={dataType}
          selectedOption={subDataType}
          handleSelect={handleDataTypeChange}
        />
      </div>
    </nav>
  );
}