import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { parseISO, format } from 'date-fns';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const DATE_COLOR = '#aaaaaa';
const AVG_COLOR = '#6c6c6c';

export default function calcMaxValueDateOptions(chartData, address) {
  const min = Math.min(...chartData.maxs.dayOfSeason);
  const max = Math.max(...chartData.maxs.dayOfSeason);

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: 'Date of Annual Maximum Chill Units',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.maxs.dayOfSeason,
        name: 'Date Occurred',
        color: DATE_COLOR,
        zIndex: 1,
      },
      {
        data: new Array(chartData.maxs.subsetSize - 1)
          .fill(null)
          .concat(chartData.maxs.dateOccurredRunningAverage),
        name: '10-year Running Average',
        color: AVG_COLOR,
      },
    ],
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        tickInterval: 30 * 24 * 3600 * 1000,
        gridLineWidth: 0,
        alignTicks: false,
        type: 'datetime',
        title: {
          text: 'Date Occurred',
          style: {
            color: DATE_COLOR,
          },
        },
        labels: {
          format: '{value:%b}',
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            label: {
              text: `New Year's Day`,
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: Date.parse('2021-01-01'),
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;
        const dateOccurred = chartData.maxs.dateOccurred[yearIdx];
        const daysIntoSeason = chartData.maxs.daysIntoSeason[yearIdx];

        const diffFromAvg =
          daysIntoSeason - chartData.maxs.avgDaysIntoSeason;
        let relativeToAverage = '';
        if (diffFromAvg > 0) {
          relativeToAverage = '+';
        }

        const avg = this.points[1];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={year} color={DATE_COLOR} />

            <div className='mc-occurred'>
              Occurred on {format(parseISO(dateOccurred), 'MMM do')}
            </div>

            <div className='mc-container'>
              <div className='mc-number'>{daysIntoSeason}</div>
              <div className='mc-units'>days into season</div>
            </div>

            <div className='mc-container'>
              <div className='mc-number'>
                {relativeToAverage}
                {diffFromAvg}
              </div>
              <div className='mc-units'>days from average</div>
            </div>

            {avg && (
              <div className='mc-container'>
                <div className='mc-number'>
                  {format(new Date(avg.y), 'MMM d')}
                </div>
                <div className='mc-units'>
                  {year + 1 - chartData.maxs.subsetSize}-{year} average
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  };
}
