import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { MapProvider } from './contexts/map.context';
import { LocationProvider } from './contexts/location.context';
import { MarkerPopupProvider } from './contexts/marker_popup.context';
import { ChartProvider } from './contexts/chart.context';
import { DataProvider } from './contexts/data.context';
import { LoadingProvider } from './contexts/loading.context';
import { ThresholdsProvider } from './contexts/threshold.context';
import { OverlayProvider } from './contexts/overlay.context';
import { DataTypeProvider } from './contexts/data-type.context';
import { TextPanelProvider } from './contexts/text-panel.context';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <DataTypeProvider>
        <LocationProvider>
          <MapProvider>
            <MarkerPopupProvider>
              <ChartProvider>
                <ThresholdsProvider>
                  <OverlayProvider>
                    <DataProvider>
                      <TextPanelProvider>
                        <App />
                      </TextPanelProvider>
                    </DataProvider>
                  </OverlayProvider>
                </ThresholdsProvider>
              </ChartProvider>
            </MarkerPopupProvider>
          </MapProvider>
        </LocationProvider>
      </DataTypeProvider>
    </LoadingProvider>
  </React.StrictMode>
);
