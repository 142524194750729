import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { storeItem } from './app-globals';
import { chartOptionsObj, allChartOptions, loadInitialChartOptions } from '../utilities/charts/chart-options-functions';

import { DataTypeContext } from './data-type.context';

const CHART_LS_KEY = 'chart-options';

// Set up initial state of context
export const ChartContext = createContext({
  isOpen: false,
  toggleChart: () => null,
  dataName: '',
  selectedCharts: [],
  updateSelectedCharts: () => null,
  chartOptions: [],
  selectedChartOptionFunctions: []
});

// Set up context provider
export const ChartProvider = ({ children }) => {
  const { dataType, subDataType, isChill, isGDD, isHeavyRain } = useContext(DataTypeContext);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState(loadInitialChartOptions(CHART_LS_KEY));

  let dataName = dataType;
  let type = null;
  if (isGDD) {
    dataName = subDataType + ' GDDs';
    type = 'gddCharts';
  } else if (isChill) {
    type = 'chillCharts';
  } else if (isHeavyRain) {
    type = 'heavyRainCharts'
  }

  useEffect(() => {
    storeItem(CHART_LS_KEY, JSON.stringify(selectedCharts));
  }, [selectedCharts]);

  const updateSelectedCharts = (changingChartOption) => {
    const newSelectedCharts = JSON.parse(JSON.stringify(selectedCharts));
    
    if (newSelectedCharts[type].includes(changingChartOption)) {
      newSelectedCharts[type] = newSelectedCharts[type].filter((r) => r !== changingChartOption);
    } else {
      newSelectedCharts[type] = [...newSelectedCharts[type], changingChartOption];
    }

    setSelectedCharts(newSelectedCharts);
  };

  // Handles toggling chart open and closed
  const toggleChart = () => {
    setIsOpen((prev) => !prev);
  };

  const value = {
    isOpen,
    toggleChart,
    dataName,
    selectedCharts: selectedCharts[type],
    updateSelectedCharts,
    chartOptions: allChartOptions[type],
    selectedChartOptionFunctions: Object.entries(chartOptionsObj[type]).reduce((acc, [k, func]) => {
      if (selectedCharts[type].includes(k)) acc.push(func);
      return acc;
    }, [])
  };
  return (
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

ChartProvider.propTypes = {
  children: PropTypes.node,
};