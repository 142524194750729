import React, { useContext, useState } from 'react';

import { ChartContext } from '../../contexts/chart.context';
import { OverlayContext } from '../../contexts/overlay.context';
import { LocationContext } from '../../contexts/location.context';
import { DataTypeContext } from '../../contexts/data-type.context';

import LocationDisplay from './location-display/location-display.component';
import RegionSelector from './selectors/region-selector.component';
import DepartureSelector from './selectors/departure-selector.component';
import ThresholdSelector from './selectors/threshold-selector/threshold-selector.component';
import ChartSelector from './selectors/chart-selector.component';
import GDDStartDateSelector from './selectors/gdd-start-date-selector.component';
import GDDBaseSelector from './selectors/gdd-base-selector.component';
import Button from '../button/button.component';
import HeavyRainStartDateSelector from './selectors/heavy-rain-start-date-selector.component';
import HeavyRainThresholdSelector from './selectors/heavy-rain-threshold-selector.component';

import './options-panel.styles.scss';

export default function OptionsPanel() {
  const { isOpen, toggleChart, selectedCharts } = useContext(ChartContext);
  const { isRegionSelected } = useContext(OverlayContext);
  const { selected } = useContext(LocationContext);
  const { isGDD, isHeavyRain } = useContext(DataTypeContext);

  const showRegionSelector = !isOpen;
  const showThresholdSelector = isOpen && selectedCharts.includes('Date of Threshold Exceedance');
  const showHeavyRainThresholdSelector = isOpen && isHeavyRain;
  const showChartSelector = isOpen;
  const showShowHideBtn = selected !== null;
  const showDepartureSelector = !isOpen && isRegionSelected;
  const showGDDSDateSelector = isOpen && isGDD;
  const showHeavyRainSDateSelector = isOpen && isHeavyRain;
  const showBaseSelector = isOpen && isGDD;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleHideShow = () => {
    setExpanded(false);
    toggleChart();
  };

  return (
    <div className='options-panel-container'>
      <LocationDisplay address={selected?.address} />
      <div className='options-panel-selectors'>
        {showRegionSelector &&
          <RegionSelector expanded={expanded} handleChange={handleChange} />
        }
        {showDepartureSelector &&
          <DepartureSelector expanded={expanded} handleChange={handleChange} />
        }
        {showChartSelector &&
          <ChartSelector expanded={expanded} handleChange={handleChange} />
        }
        {showThresholdSelector &&
          <ThresholdSelector expanded={expanded} handleChange={handleChange} />
        }
        {showHeavyRainThresholdSelector &&
          <HeavyRainThresholdSelector expanded={expanded} handleChange={handleChange} />
        }
        {showGDDSDateSelector &&
          <GDDStartDateSelector expanded={expanded} handleChange={handleChange} />
        }
        {showHeavyRainSDateSelector &&
          <HeavyRainStartDateSelector expanded={expanded} handleChange={handleChange} />
        }
        {showBaseSelector &&
          <GDDBaseSelector expanded={expanded} handleChange={handleChange} />
        }
      </div>
    
      {showShowHideBtn &&
        <Button buttonType='centeredSmall' onClick={handleHideShow} style={{ margin: '12px auto' }}>
          {isOpen ? 'Hide Charts' : 'Show Charts'}
        </Button>
      }
    </div>
  );
}
