import React, { useContext } from 'react';
import {
  Tabs,
  Tab,
  Box,
  useMediaQuery
} from '@mui/material';


import { DataTypeContext } from '../../contexts/data-type.context';
import { TextPanelContext } from '../../contexts/text-panel.context';

import MarkdownToJsx from '../markdown-to-jsx/markdown-to-jsx.component';

import './text-panel.styles.scss';


function a11yProps(idx) {
  return {
    id: `vertical-tab-${idx}`,
    'aria-controls': `vertical-tabpanel-${idx}`,
  };
}

export default function TextPanel() {
  const { tabNames, tabSelected, setTabIdx } = useContext(TextPanelContext);
  const { dataType } = useContext(DataTypeContext);

  const isSmallScreen = useMediaQuery('(max-width:550px)');
  console.log(isSmallScreen);

  const mdPath = `${process.env.PUBLIC_URL}/markdown/${tabNames[tabSelected].split(" ").join("-").toLowerCase()}`;
  const selectedDataType = dataType.split(" ").join("-").toLowerCase();

  const handleTabChange = (e, newTab) => {
    setTabIdx(newTab);
  };

  return (
    <Box className='text-panel'>
      <Box
        sx={{ flexGrow: 1, display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}
      >
        <Tabs
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen}
          allowScrollButtonsMobile={isSmallScreen}
          value={tabSelected}
          onChange={handleTabChange}
          aria-label="Tabs controlling text content"
          sx={{ border: 1, borderColor: 'divider', minWidth: isSmallScreen ? 'unset' : 'fit-content', height: 'fit-content' }}
        >
          {tabNames.map((name, idx) => <Tab label={name} key={'tab-' + name} {...a11yProps(idx)} sx={{ padding: '12px 8px' }} />)}
        </Tabs>

        <Box
          role="tabpanel"
          id={`vertical-tabpanel-${tabSelected}`}
          aria-labelledby={`vertical-tab-${tabSelected}`}
          className='text-panel-md-content'
        >
          <h3 style={{ margin: '4px 0px 0px' }}>{dataType}</h3>
          <MarkdownToJsx
            path={`${mdPath}/${selectedDataType}.md`}
            fallback={`${mdPath}/default.md`}
          />
        </Box>
      </Box>
    </Box>
  );
}