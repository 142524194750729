import React, { useContext } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { DataContext } from '../../contexts/data.context';
import { LocationContext } from '../../contexts/location.context';
import { ChartContext } from '../../contexts/chart.context';
import { DataTypeContext } from '../../contexts/data-type.context';
import { ThresholdsContext } from '../../contexts/threshold.context';

import Chart from './chart/chart.component';

import './charts.styles.scss';

export default function Charts() {
  const { isGDD, isHeavyRain } = useContext(DataTypeContext);
  const { chartData, gddChartBase } = useContext(DataContext);
  const { selected } = useContext(LocationContext);
  const { dataName, selectedChartOptionFunctions } = useContext(ChartContext);
  const { heavyRainThreshold } = useContext(ThresholdsContext);

  const { width, height, ref } = useResizeDetector();
  
  return (
    <div className='charts-container' ref={ref}>
      {selectedChartOptionFunctions.map((func, i) => {
        let options, key;
        try {
          options = func(chartData, selected.address, isHeavyRain ? heavyRainThreshold : (isGDD ? `Base ${gddChartBase} GDDs` : dataName));
          key = options.title.text;
        } catch {
          options = {};
          key = new Date().getTime();
        }
        return (
          <React.Fragment key={key + String(i)}>
            {i !== 0 && <div className='hr' />}
            <Chart
              options={options}
              width={width}
              height={height / 2 - 10 || 200}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
